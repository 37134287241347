import React, { Fragment, useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UsersTableHeaderContact, Website, BillingInformation, CompanyInformation, InlineForm, InlineFormWithLabel, InlineFormWithoutLabel, HorizontalFormLayout, MegaForm, Submit, Cancel, AccountInformation, Option, Login, ContactNumber, CompanyName, YourName, Checkboxes, Radios, Disabled } from "../../../constant";
import { PlusSquare } from 'react-feather';
import InputMask from "react-input-mask";
import { getCompany, updateBusinessIntelligence, editCompanyAdmin, findCitiesByName, createUpdateContactBuyer, updateStatusNewContactBuyer, createBusinessIntelligence, updateLogo, getAllStates, findBuyerCategory } from '../../../api/index';
import AsyncSelect from 'react-select/async';
import one from '../../../assets/images/user/1.jpg';
import Select from 'react-select';
import SweetAlert from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { b64toBlob } from "../helpers/index";
import DefaultSpinner from "../helpers/defaultSpinner";
import { useParams } from "react-router-dom";
const BuyerEdit = () => {

    const [buyer, setBuyer] = useState({});
    const [file, setFile] = useState();
    const [warning, setWarning] = useState({ open: false, text: '' });
    const [defaultOpt, setDefaultOpt] = useState([]);
    const [defaultStates, setDefaultStates] = useState([]);
    const [defaultCategory, setDefaultCategory] = useState([]);
    const [contact, setContact] = useState({
        id: null,
        name: "",
        email: "",
        category: "",
        phone: "",
        role: "",
        city: 0,
    });
    const [company, setCompany] = useState({
        name: "",
        email: "",
        user: "",
        role: "",
        phone: "",
        userState: [],
        addresses: [],
        categories: [],
        userCategory: null,
        cnpj: "",
        website: "",
        status: true,
        approval: true,
    });
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [openContact, setOpenContact] = useState(false);
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();

    const consumerRef = useRef();
    const storeSizeRef = useRef();
    const assortmentRef = useRef();
    const apresentationRef = useRef();
    const termsRef = useRef();
    const logisticRef = useRef();
    const tradeRef = useRef();
    const insightsRef = useRef();

    const params = useParams();

    const options = [
        { value: 'Alimentos', label: 'Alimentos' },
        { value: 'Bebidas', label: 'Bebidas' },
        { value: 'Saudáveis', label: 'Saudáveis' },
        { value: 'Perecíveis', label: 'Perecíveis' },
        { value: 'Frios', label: 'Frios' },
        { value: 'Laticinios', label: 'Laticinios' },
        { value: 'Mercearia', label: 'Mercearia' },
        { value: 'Mercearia Salgada', label: 'Mercearia Salgada' },
        { value: 'Mercearia Seca', label: 'Mercearia Seca' },
        { value: 'Saudáveis', label: 'Saudáveis' },
        { value: 'Carnes', label: 'Carnes' },
        { value: 'Aves', label: 'Aves' },
        { value: 'Suinos', label: 'Suinos' },
        { value: 'Fiambreria', label: 'Fiambreria' },
        { value: 'Congelados', label: 'Congelados' },
        { value: 'Padaria', label: 'Padaria' },
    ]

    function removeFormat(e) {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        const plainText = stripFormatting(text);
        document.execCommand("insertHTML", false, plainText);
    }

    function stripFormatting(text) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/html");
        const plainText = doc.body.textContent || "";
        return plainText;
    }

    const fetchBuyer = async () => {
        const { data: { msg: resp } } = await getCompany({ id: params.id });

        const getStates = await getAllStates();
        const states = getStates.data.msg.map((state) => {
            return {
                value: state.id,
                label: state.desc
            }

        })
        setDefaultStates(states);

        const getBuyerCategory = await findBuyerCategory();
        setDefaultCategory(getBuyerCategory.data.msg.map((category) => {
            return {
                value: category.id,
                label: category.desc
            }

        }));

        let categoriesFormated = resp.categories.map((category) => {
            return {
                value: category.id,
                label: category.desc
            }
        })

        let userStates = [];

        resp.users[0].userState.forEach(state => {
            userStates.push(state.id_state);
        });

        const userStateFormated = states.filter((state) => {
            return userStates.includes(state.value);
        })

        setBuyer(resp);
        setCompany({
            name: resp.name,
            email: resp.users[0].email,
            phone: resp.users[0].phone,
            user: resp.users[0].name,
            userCategory: resp.users[0].category,
            role: resp.users[0].role,
            userState: userStateFormated,
            addresses: resp.addresses,
            categories: categoriesFormated,
            cnpj: resp.cnpj,
            website: resp.site_url ? resp.site_url : "",
            qty_companies: resp.qty_companies,
            status: resp.sit_active,
            approval: resp.sit_approval ? true : false,
        })

        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }

    useEffect(() => {
        fetchBuyer();
    }, [])

    const handleError = (warning) => {
        setWarning(warning);
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
        setLoading(false)
    }

    const handleEditContact = async (contact) => {
        setDefaultOpt([]);
        if (contact.city) {
            const resp = await findCitiesByName({ name: contact.city.desc });

            setDefaultOpt(resp.data.msg.map((city) => {
                return {
                    value: city.id,
                    label: city.desc
                };
            }));
        }

        const newContact = {
            ...contact,
            city: contact.city ? contact.city.id : null
        }

        setContact(newContact);
        reset(newContact);
        setOpenContact(!openContact);
    }

    const handleStatusContact = (id, status) => {
        const textFirst = status ? "inativar" : "ativar";
        const textSecond = status ? "Inativado" : "Ativado";
        SweetAlert.fire({
            title: `Deseja ${textFirst} o Contato?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            reverseButtons: true
        })
            .then(async (result) => {
                if (result.value) {
                    const { data } = await updateStatusNewContactBuyer({ id: id, sit_approval: !status });

                    if (data.success) {
                        SweetAlert.fire({
                            title: `${textSecond} com sucesso!`,
                            icon: 'success',
                        }
                        ).then(async (result) => {
                            await fetchBuyer();
                        })
                    } else {
                        SweetAlert.fire(
                            'Erro',
                            `${data.msg}`
                        )
                    }
                }
            })
    }

    const handleSelect = async (name, value) => {
        setContact({
            ...contact,
            [name]: value
        })
        setCompany({
            ...company,
            [name]: value
        })
        setValue(name, value);
    }

    const handleSelectCompany = async (name, value) => {
        setCompany({
            ...company,
            [name]: value
        })
        setValue(name, value);
    }

    const handleInputvalue = (name, value) => {
        const regexCpnj = new RegExp("^[0-9]*$");
        switch (name) {
            case "cnpj":
                if (regexCpnj.test(String(value))) {
                    return value
                }
                return company.cnpj
            default:
                return value
        }
    }

    const handleCompany = (e) => {
        const value = e.target.name == "status" || e.target.name == "approval" ? e.target.checked : e.target.value;
        setCompany({
            ...company,
            [e.target.name]: handleInputvalue(e.target.name, value)
        });
    }

    const handleContact = (e) => {
        const value = e.target.value;
        setContact({
            ...contact,
            [e.target.name]: value
        });
        setValue(e.target.name, value);
    }

    const handleNewContact = () => {
        setOpenContact(true);
        setValue("id", null);
        setContact({
            name: "",
            email: "",
            category: "",
            phone: "",
            role: "",
            city: 0,
        });
        setDefaultOpt([]);
    }

    const onSubmitContact = async data => {
        setLoading(true);
        if (Object.keys(data).length) {
            const payload = {
                id: data.id ? data.id : null,
                cnpj: company.cnpj,
                name: data.name,
                role: data.role,
                email: data.email,
                city: data.city,
                phone: data.phone,
                category: data.category ? data.category : null,
            }

            const resp = await createUpdateContactBuyer(payload);

            if (resp.status == 200) {
                setOpenContact(!openContact);
                fetchBuyer();
            }

        } else {
            errors.showMessages();
        }
        setLoading(false);
    };

    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length >= 3) {
            const resp = await findCitiesByName({ name: inputValue });

            callback(resp.data.msg.map((city) => {
                return {
                    value: city.id,
                    label: city.desc
                };
            }));
        }
    };

    const onSubmitCompany = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            id: buyer.id,
            ...company
        }

        const resp = await editCompanyAdmin(payload);

        if (file) {
            await updateLogo(payload, (file ? new File([b64toBlob(file.split(",")[1], 'image/png')], "filename.png") : null));
        }

        resp?.status == 200 ? fetchBuyer() : handleError({ open: true, text: resp?.data?.msg })

        setLoading(false);
    };

    const readUrl = (event) => {
        if (event.target.files.length === 0)
            return;

        // if (mimeType.match(/image\/*/) == null) {
        //     return;
        // }
        setBuyer({
            ...buyer,
            logo: URL.createObjectURL(event.target.files[0])
        })
        // Image upload

        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            setFile(reader.result)
        }
    }

    const handleSubmitBI = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            id: buyer?.bi_company?.id,
            cnpj: buyer?.cnpj,
            intelligence: {
                name: buyer?.name,
                consumer: consumerRef.current.innerHTML,
                assortment: assortmentRef.current.innerHTML,
                storeSize: storeSizeRef.current.innerHTML,
                introduction: apresentationRef.current.innerHTML,
                tradeActions: termsRef.current.innerHTML,
                logistic: logisticRef.current.innerHTML,
                tradeMarketing: tradeRef.current.innerHTML,
                insights: insightsRef.current.innerHTML,
            }
        }

        let resp;

        if (payload.id) {
            resp = await updateBusinessIntelligence(payload);
        } else {
            resp = await createBusinessIntelligence(payload);
        }

        resp && resp.status == 200 ? fetchBuyer() : handleError({ open: true, text: resp.data.msg })

        setLoading(false);
    }

    return (
        <Fragment>
            <Breadcrumb parent="Varejistas" title="Edição" />
            <Alert className={`alert-dismissible ${warning.open ? "show" : "fade"}`} color="danger dark"
                isOpen={warning.open}>
                {warning.text}
                <Button className="btn-close" color="default" onClick={() => setWarning({ open: false, text: warning.text })} ></Button>
            </Alert>
            <Modal centered isOpen={openContact}>
                <ModalHeader>Contato</ModalHeader>
                <form key={1} className="theme-form needs-validation" noValidate="" onSubmit={handleSubmit(onSubmitContact)}>
                    <ModalBody>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor="name">Nome</label>
                            <div className="col-sm-9">
                                <input className="form-control" name="name" type="text" onChange={(e) => handleContact(e)} value={contact.name} placeholder="Nome" />
                                <span>{errors.name && 'Campo obrigatório'}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor="email">Email</label>
                            <div className="col-sm-9">
                                <input className="form-control" id="email" autoComplete="off" name="email" onChange={(e) => handleContact(e)} value={contact.email} type="email" placeholder="Email" />
                                <span>{errors.email && 'Campo obrigatório'}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor="tel">Telefone</label>
                            <div className="col-sm-9">
                                <InputMask className="form-control" mask="(99) 9999-999999" maskPlaceholder={null} id="inputnumber" type="tel" name="phone" onChange={(e) => handleContact(e)} value={contact.phone} placeholder="Telefone" />
                                <span>{errors.phone && 'Campo obrigatório'}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor="role">Cargo</label>
                            <div className="col-sm-9">
                                <input className="form-control" name="role" type="text" onChange={(e) => handleContact(e)} value={contact.role} placeholder="Cargo" />
                                <span>{errors.role && 'Campo obrigatório'}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor="role">Categoria</label>
                            <div className="col-sm-9">
                                <Select options={options} className="form-control p-0" name="role" type="text" value={contact.category ? options.find((opt) => { return opt.label == contact.category }) : ""} placeholder="Categoria"
                                    onChange={(e) => handleSelect("category", e.label)} />
                                <span>{errors.role && 'Campo obrigatório'}</span>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor="city">Cidade</label>
                            <div className="col-sm-9">
                                <AsyncSelect loadOptions={loadOptions} defaultOptions={defaultOpt} value={contact.city ? defaultOpt.find((opt) => { return opt.value == contact.city }) : ""} className="form-control p-0" name="city" type="text"
                                    onChange={(e) => handleSelect("city", e.value)} placeholder="Cidade" />
                                <span>{errors.city && 'Campo obrigatório'}</span>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="d-flex justify-content-between">
                        <Button color="secondary" onClick={() => setOpenContact(!openContact)}>
                            Cancel
                        </Button>
                        <Button color="primary" type="submit">
                            {loading ? <DefaultSpinner /> : "Salvar"}
                        </Button>{' '}
                    </ModalFooter>
                </form>
            </Modal>
            {Object.keys(buyer).length > 0 &&
                <Card>
                    <CardHeader>
                        <h5>{buyer.name} </h5>
                    </CardHeader>
                    <CardBody className="tabbed-card">
                        <Nav className="nav-pills nav-primary">
                            <NavItem role="button">
                                <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                    <i className="icofont icofont-man-in-glasses"></i>Perfil
                                </NavLink>
                            </NavItem>
                            <NavItem role="button">
                                <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                    <i className="icofont icofont-man-in-glasses"></i>Inteligência
                                </NavLink>
                            </NavItem>
                            <NavItem role="button">
                                <NavLink className={activeTab === '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
                                    <i className="icofont icofont-contacts"></i>Contatos
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <div className="card">
                                    <div className="card-body">
                                        <form key={2} className="theme-form">
                                            <div className="user-image mb-5">
                                                <div className="avatar d-flex justify-content-center">
                                                    <label htmlFor="image-avatar">
                                                        <img className="pro border" style={{ maxWidth: "225px", maxHeight: "225px" }} role="button" alt="" src={buyer?.logo ? buyer?.logo : one} data-intro="This is Profile image" />
                                                        <input className="pencil d-none" id="image-avatar" type="file" accept="image/png, image/jpeg" onChange={(e) => readUrl(e)} />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-1 col-form-label" htmlFor="name">Empresa</label>
                                                <div className="col-sm-11">
                                                    <input className="form-control" required id="name" autoComplete="off" name="name" onChange={(e) => handleCompany(e)} value={company.name} type="text" placeholder="Nome da Empresa" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-1 col-form-label" htmlFor="user">Usuário</label>
                                                <div className="col-sm-11">
                                                    <input className="form-control" required id="user" autoComplete="off" name="user" onChange={(e) => handleCompany(e)} value={company.user} type="text" placeholder="Usuário" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-1 col-form-label" htmlFor="email">Email</label>
                                                <div className="col-sm-11">
                                                    <input className="form-control" required id="email" autoComplete="off" name="email" onChange={(e) => handleCompany(e)} value={company.email} type="email" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-1 col-form-label" htmlFor="cnpj">CNPJ</label>
                                                <div className="col-sm-5">
                                                    <input className="form-control" id="cnpj" required name="cnpj" type="text" onChange={(e) => handleCompany(e)} value={company.cnpj} placeholder="CNPJ" />
                                                </div>
                                                <label className="col-sm-1 col-form-label" htmlFor="website">Website</label>
                                                <div className="col-sm-5">
                                                    <input className="form-control" id="website" name="website" type="url" onChange={(e) => handleCompany(e)} value={company.website} placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-1 col-form-label" htmlFor="role">Cargo</label>
                                                <div className="col-sm-5">
                                                    <input className="form-control" required id="role" autoComplete="off" name="role" onChange={(e) => handleCompany(e)} value={company.role} type="text" placeholder="Cargo" />
                                                </div>
                                                <label className="col-sm-1 col-form-label" htmlFor="phone">Telefone</label>
                                                <div className="col-sm-5">
                                                    <InputMask className="form-control" required mask="(99) 99999-9999" maskPlaceholder={null} id="inputnumber" type="tel" name="phone" onChange={(e) => handleCompany(e)} value={company.phone} placeholder="Telefone" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-1 col-form-label" htmlFor="state">Estados</label>
                                                <div className="col-sm-5">
                                                    <AsyncSelect isMulti defaultOptions={defaultStates} value={company.userState} className="form-control p-0" required name="state" type="text"
                                                        onChange={(e) => handleSelectCompany("userState", e)} placeholder="Estado" />
                                                </div>
                                                <label className="col-sm-1 col-form-label" htmlFor="buyerCategory">Varejo</label>
                                                <div className="col-sm-5">
                                                    <AsyncSelect isMulti defaultOptions={defaultCategory} value={company.categories} className="form-control p-0" required name="buyerCategory" type="text"
                                                        onChange={(e) => handleSelectCompany("categories", e)} placeholder="Tipo de varejo" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-1 col-form-label" htmlFor="userCategory">Categoria</label>
                                                <div className="col-sm-5">
                                                    <Select options={options} className="form-control p-0" name="userCategory" type="text" value={company.userCategory ? options.find((opt) => { return opt.label == company.userCategory }) : null} placeholder="Categoria"
                                                        onChange={(e) => handleSelect("userCategory", e.label)} />
                                                </div>
                                                <label className="col-sm-1 col-form-label" htmlFor="qty_companies">Lojas</label>
                                                <div className="col-sm-5">
                                                    <input className="form-control" type="number" onChange={(e) => handleCompany(e)} name="qty_companies" value={company.qty_companies} placeholder="Número de lojas" />
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="form-group m-checkbox-inline mb-0 ms-1">
                                                    <div className="checkbox checkbox-solid-info" >
                                                        <input id="solid1" type="checkbox" name="status" onChange={(e) => handleCompany(e)} checked={company.status} />
                                                        <label className="user-select-none" htmlFor="solid1">Ativado</label>
                                                    </div>
                                                </div>
                                                <div className="form-group m-checkbox-inline mb-0 ms-1">
                                                    <div className="checkbox checkbox-solid-info" >
                                                        <input id="solid2" type="checkbox" name="approval" onChange={(e) => handleCompany(e)} checked={company.approval} />
                                                        <label className="user-select-none" htmlFor="solid2">Aprovado</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="card-footer text-end">
                                        <button className="btn btn-primary me-1" onClick={(e) => onSubmitCompany(e)} style={{ width: "140px", height: "40px" }}>{loading ? <DefaultSpinner /> : "Salvar"}</button>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="container-fluid">
                                    <div className="edit-profile">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <form>
                                                            <div className="form-group mb-3">
                                                                <label className="form-label">Consumidores:</label>
                                                                <div id="consumer" ref={consumerRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer.bi_company ? buyer.bi_company.consumer : "" }} role="textbox" className="form-control text-break" style={{ minHeight: '70px' }}>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <label className="form-label">Sortimento de produtos:</label>
                                                                <div id="assortment" ref={assortmentRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer.bi_company ? buyer.bi_company.assortment : "" }} role="textbox" className="form-control text-break" style={{ minHeight: '70px' }}>
                                                                </div>
                                                            </div>
                                                            <div className="form-group mb-3">
                                                                <label className="form-label">Tamanho de lojas:</label>
                                                                <div id="storeSize" ref={storeSizeRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer.bi_company ? buyer.bi_company.storeSize : "" }} role="textbox" className="form-control text-break" style={{ minHeight: '70px' }}>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <form className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group mb-3">
                                                                    <label className="form-label">Como é a apresentação de produtos?</label>
                                                                    <div id="introduction" ref={apresentationRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer.bi_company ? buyer.bi_company.introduction : "" }} role="textbox" className="form-control text-break" style={{ minHeight: '100px' }}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group mb-3">
                                                                    <label className="form-label">Termos de negociação</label>
                                                                    <div id="tradeActions" ref={termsRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer.bi_company ? buyer.bi_company.tradeActions : "" }} className="form-control text-break" style={{ minHeight: '100px' }}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group mb-3">
                                                                    <label className="form-label">Logística</label>
                                                                    <div id="logistic" ref={logisticRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer.bi_company ? buyer.bi_company.logistic : "" }} className="form-control text-break" style={{ minHeight: '100px' }}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group mb-3">
                                                                    <label className="form-label">Trade Marketing</label>
                                                                    <div id="tradeMarketing" ref={tradeRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer.bi_company ? buyer.bi_company.tradeMarketing : "" }} className="form-control text-break" style={{ minHeight: '100px' }}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group mb-3">
                                                                    <label className="form-label">Insights Comunidade</label>
                                                                    <div id="insights" ref={insightsRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer.bi_company ? buyer.bi_company.insights : "" }} className="form-control text-break" style={{ minHeight: '100px' }}>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-footer text-end">
                                                        <button className="btn btn-primary" style={{ width: "140px", height: "40px" }} onClick={(e) => handleSubmitBI(e)} type="submit">{loading ? <DefaultSpinner /> : "Salvar"}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                                <div className="d-flex justify-content-end align-items-center">
                                    <div className="btn btn-success d-flex align-items-center" onClick={() => handleNewContact()}> <PlusSquare className="m-r-5" />Novo</div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table card-table table-vcenter">
                                        <thead>
                                            <tr>
                                                {UsersTableHeaderContact.map((items, i) =>
                                                    <th className="text-center" key={i}>{items}</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {buyer.contacts.length > 0 && buyer.contacts.map((items, i) =>
                                                <tr key={i}>
                                                    <td >{items.name}</td>
                                                    <td className="text-center">{items.role}</td>
                                                    <td className="text-center">{items.email}</td>
                                                    <td className="text-center">{items.phone}</td>
                                                    <td className="text-center">{items.category}</td>
                                                    <td className="text-center">{items.city?.desc}</td>
                                                    <td className="text-end">
                                                        <div className="btn-showcase">
                                                            <button className="btn btn-primary btn-sm" onClick={() => handleEditContact(items)}>
                                                                <i className="fa fa-pencil"></i> Editar
                                                            </button>
                                                            <button className={`btn btn-sm ${items.sit_approval ? 'btn-danger' : 'btn-success'}`} style={{ width: "120px" }} onClick={() => handleStatusContact(items.id, items.sit_approval)}>
                                                                <i className="fa fa-window-close"></i> {items.sit_approval ? 'Inativar' : 'Ativar'}
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>}
        </Fragment>
    );
};

export default BuyerEdit;