import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { ShoppingCart, Users, Home } from 'react-feather';
import CountUp from 'react-countup';
import { counterCompanyAdmin } from '../../api/index'

const Dashboard = () => {
    const [infoCounter, setInfoCounter] = useState({});

    const fetchCounterInfo = async () => {
        const resp = await counterCompanyAdmin();

        setInfoCounter(resp.data.msg);
    }

    useEffect(() => {
        fetchCounterInfo();
    }, [])

    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="Dashboard" />
            <div className="container-fluid">
                <div className="d-flex justify-content-around">
                    <div className="card flex">
                        <div className="card-body ecommerce-icons text-center">
                            <Home />
                            <div><span>Marcas</span></div>
                            <h4 className="font-primary mb-0">
                                <CountUp className="counter" end={infoCounter?.brand || 0} /></h4>
                        </div>
                    </div>
                    <div className="card flex">
                        <div className="card-body ecommerce-icons text-center">
                            <ShoppingCart />
                            <div><span>Varejistas</span></div>
                            <h4 className="font-primary mb-0">
                                <CountUp className="counter" end={infoCounter?.buyer || 0} /></h4>
                        </div>
                    </div>
                    <div className="card flex">
                        <div className="card-body ecommerce-icons text-center">
                            <Users />
                            <div><span>Contatos</span></div>
                            <h4 className="font-primary mb-0">
                                <CountUp className="counter" end={infoCounter?.contact || 0} /></h4>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Dashboard;