import React, { Fragment, useState, useEffect } from 'react';
import man from '../../../assets/images/dashboard/user.png';
import { User, Mail, Lock, Settings, LogOut, Award } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import { getTip, updateTip } from '../../../api/index'
import { EditProfile, Inbox, LockScreen } from '../../../constant'
import { Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane } from 'reactstrap';

const UserMenu = () => {
    const [profile, setProfile] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [tip, setTip] = useState('');
    // auth0 profile
    const authenticated = JSON.parse(localStorage.getItem("authenticated"))
    const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))
    const navigate = useNavigate();

    const getWeekTip = async () => {
        const resp = await getTip();

        if (resp?.data?.msg.desc) {
            setTip(resp.data.msg.desc);
        }
    }

    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || man);
        getWeekTip();
    }, []);

    const Logout_From_Auth0 = () => {
        localStorage.removeItem("token")
        localStorage.setItem("authenticated", false)
        navigate(`${process.env.PUBLIC_URL}/login`)
        // logout()
    }

    const saveTip = async () => {
        await updateTip({ desc: tip });
        setOpenModal(!openModal)
    }

    return (
        <Fragment>
            <Modal centered isOpen={openModal}>
                <ModalHeader>Dica da Semana</ModalHeader>
                <ModalBody>
                    <div className="d-flex p-2">
                        <textarea
                            className="cnt w-100 p-2 b-r-10"
                            placeholder="Dica da semana"
                            minLength={10}
                            style={{ height: "100px" }}
                            value={tip}
                            onChange={(e) => setTip(e.target.value)}
                        ></textarea>
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                    <Button color="secondary" onClick={() => setOpenModal(!openModal)}>
                        Cancelar
                    </Button>
                    <Button color="primary" onClick={() => saveTip()}>
                        Atualizar
                    </Button>{' '}
                </ModalFooter>
            </Modal>
            <li className="onhover-dropdown">
                <div className="d-flex align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={profile} alt="header-user" />
                    <div className="dotted-animation">
                        <span className="animate-circle"></span>
                        <span className="main-circle"></span>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    {/* <li><Link to={`${process.env.PUBLIC_URL}/users/userEdit`}><User />{EditProfile}</Link></li>
                    <li><Link to={`${process.env.PUBLIC_URL}/email-app/emailDefault`}><Mail />{Inbox}</Link></li>
                    <li><a href="#javascript"><Lock />{LockScreen}</a></li>
                    <li><a href="#javascript"><Settings />{"Settings"}</a></li> */}
                    <li><a onClick={() => setOpenModal(!openModal)} href="#javascript" ><Award />Dica</a></li>
                    <li><a onClick={() => Logout_From_Auth0()} href="#javascript" ><LogOut /> {"Log out"}</a></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default UserMenu;