import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Alert, Button } from 'reactstrap';
import { Website } from "../../../constant";
import InputMask from "react-input-mask";
import { getCompany, editCompanyAdmin, getTypeKnown } from '../../../api/index';
import DefaultSpinner from "../helpers/defaultSpinner";
import Select from 'react-select';
import { useParams } from "react-router-dom";
const BrandEdit = () => {

    const [brand, setBrand] = useState({});
    const [warning, setWarning] = useState({ open: false, text: '' });
    const [known, setKnown] = useState([]);
    const [company, setCompany] = useState({
        name: "",
        email: "",
        phone: "",
        cnpj: "",
        website: "",
        status: true,
        approval: true,
        test: false,
    });
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('1');

    const params = useParams();

    const fetchKnown = async () => {
        const { data: { msg: resp } } = await getTypeKnown();

        setKnown(
            resp.map((type) => {
                return {
                    value: type.id,
                    label: type.desc
                };
            })
        );
    }

    const fetchBrand = async () => {
        const { data: { msg: resp } } = await getCompany({ id: params.id });

        setBrand(resp);
        setCompany({
            name: resp.name,
            email: resp.users[0].email,
            phone: resp.users[0].phone,
            id_type_known: resp.id_type_known,
            cnpj: resp.cnpj,
            website: resp.site_url ? resp.site_url : "",
            status: resp.sit_active,
            test: resp.sit_test == 1 ? true : false,
        })

        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }

    useEffect(() => {
        fetchKnown();
        fetchBrand();
    }, [])

    const handleError = (warning) => {
        setWarning(warning);
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
        setLoading(false)
    }

    const handleInputvalue = (name, value) => {
        const regexCpnj = new RegExp("^[0-9]*$");
        switch (name) {
            case "cnpj":
                if (regexCpnj.test(String(value))) {
                    return value
                }
                return company.cnpj
            default:
                return value
        }
    }

    const handleCompany = (e) => {
        const value = e.target.name == "status" || e.target.name == "test" ? e.target.checked : e.target.value;
        setCompany({
            ...company,
            [e.target.name]: handleInputvalue(e.target.name, value)
        });
    }

    const handleSelect = async (name, value) => {
        setCompany({
            ...company,
            [name]: value
        })
    }

    const onSubmitCompany = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            id: brand.id,
            ...company
        }

        const resp = await editCompanyAdmin(payload);

        resp?.status == 200 ? fetchBrand() : handleError({ open: true, text: resp.data.msg })

        setLoading(false);
    };

    return (
        <Fragment>
            <Breadcrumb parent="Varejistas" title="Edição" />
            <Alert className={`alert-dismissible ${warning.open ? "show" : "fade"}`} color="danger dark"
                isOpen={warning.open}>
                {warning.text}
                <Button className="btn-close" color="default" onClick={() => setWarning({ open: false, text: warning.text })} ></Button>
            </Alert>
            {Object.keys(brand).length > 0 &&
                <Card>
                    <CardHeader>
                        <h5>{brand.name} </h5>
                    </CardHeader>
                    <CardBody className="tabbed-card">
                        <Nav className="nav-pills nav-primary">
                            <NavItem role="button">
                                <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                    <i className="icofont icofont-man-in-glasses"></i>Perfil
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <div className="card">
                                    <div className="card-body">
                                        <form key={2} className="theme-form">
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label" htmlFor="name">Empresa</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" required id="name" autoComplete="off" name="name" onChange={(e) => handleCompany(e)} value={company.name} type="text" placeholder="Nome da Empresa" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label" htmlFor="email">Email</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" required id="email" autoComplete="off" name="email" onChange={(e) => handleCompany(e)} value={company.email} type="email" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label" htmlFor="phone">Telefone</label>
                                                <div className="col-sm-9">
                                                    <InputMask className="form-control" required mask="(99) 9999-999999" maskPlaceholder={null} id="phone" type="tel" name="phone" onChange={(e) => handleCompany(e)} value={company.phone} placeholder="Telefone" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label" htmlFor="cnpj">CNPJ</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" id="cnpj" required name="cnpj" type="text" onChange={(e) => handleCompany(e)} value={company.cnpj} placeholder="Cnpj" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label" htmlFor="known">Como Chegou</label>
                                                <Select options={known} className="col-sm-9" required name="known" type="text" value={known.find((opt) => { return opt.value == company.id_type_known })} placeholder="Como chegou na Plataforma"
                                                    onChange={(e) => handleSelect("id_type_known", e.value)} />
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label" htmlFor="website">{Website}</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" id="website" name="website" type="url" onChange={(e) => handleCompany(e)} value={company.website} placeholder="Website" />
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <div className="form-group m-checkbox-inline mb-0 ms-1">
                                                    <div className="checkbox checkbox-solid-info" >
                                                        <input id="solid1" type="checkbox" name="status" onChange={(e) => handleCompany(e)} checked={company.status} />
                                                        <label className="user-select-none" htmlFor="solid1">Ativado</label>
                                                    </div>
                                                </div>
                                                <div className="form-group m-checkbox-inline mb-0 ms-1">
                                                    <div className="checkbox checkbox-solid-info" >
                                                        <input id="solid2" type="checkbox" name="test" onChange={(e) => handleCompany(e)} checked={company.test} />
                                                        <label className="user-select-none" htmlFor="solid2">Teste</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="card-footer text-end">
                                        <button className="btn btn-primary me-1" onClick={(e) => onSubmitCompany(e)} style={{ width: "140px", height: "40px" }}>{loading ? <DefaultSpinner /> : "Salvar"}</button>
                                    </div>
                                </div>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>}
        </Fragment>
    );
};

export default BrandEdit;