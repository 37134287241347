import React, { Fragment, useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Card, CardHeader, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import one from '../../../assets/images/user/1.jpg';
import { PlusSquare } from 'react-feather';
import AsyncSelect from 'react-select/async';
import { createBusinessIntelligence, getAllStates, findBuyerCategory } from '../../../api/index';
import DefaultSpinner from "../helpers/defaultSpinner";
import { b64toBlob } from "../helpers/index";
import { useNavigate } from "react-router-dom";
const FakeBuyersCreate = () => {

    const [buyer, setBuyer] = useState({});
    const [file, setFile] = useState();
    const [qtyCompanies, setQtyCompanies] = useState(1);
    const [states, setStates] = useState([]);
    const [typeBuyer, setTypeBuyer] = useState([]);
    const [defaultStates, setDefaultStates] = useState([]);
    const [defaultCategory, setDefaultCategory] = useState([]);
    const [warning, setWarning] = useState({ open: false, text: '' });
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('1');

    const navigate = useNavigate();

    const consumerRef = useRef();
    const nameRef = useRef();
    const storeSizeRef = useRef();
    const assortmentRef = useRef();
    const apresentationRef = useRef();
    const termsRef = useRef();
    const logisticRef = useRef();
    const tradeRef = useRef();
    const insightsRef = useRef();

    const handleError = (warning) => {
        setWarning(warning);
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
        setLoading(false)
    }

    function removeFormat(e) {
        e.preventDefault();
        const text = e.clipboardData.getData("text/plain");
        const plainText = stripFormatting(text);
        document.execCommand("insertHTML", false, plainText);
    }

    function stripFormatting(text) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/html");
        const plainText = doc.body.textContent || "";
        return plainText;
    }

    useEffect(async () => {
        const getStates = await getAllStates();
        const bistates = getStates.data.msg.map((state) => {
            return {
                value: state.id,
                label: state.desc
            }

        })
        setDefaultStates(bistates);

        const getBuyerCategory = await findBuyerCategory();
        const categories = getBuyerCategory.data.msg.map((category) => {
            return {
                value: category.id,
                label: category.desc
            }

        })
        setDefaultCategory(categories);
    }, [])

    const readUrl = (event) => {
        if (event.target.files.length === 0)
            return;

        // if (mimeType.match(/image\/*/) == null) {
        //     return;
        // }
        setBuyer({
            ...buyer,
            logo: URL.createObjectURL(event.target.files[0])
        })
        // Image upload

        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            setFile(reader.result)
        }
    }

    const handleSubmitBI = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            intelligence: {
                name: nameRef.current.innerHTML,
                consumer: consumerRef.current.innerHTML,
                assortment: assortmentRef.current.innerHTML,
                storeSize: storeSizeRef.current.innerHTML,
                introduction: apresentationRef.current.innerHTML,
                tradeActions: termsRef.current.innerHTML,
                logistic: logisticRef.current.innerHTML,
                tradeMarketing: tradeRef.current.innerHTML,
                insights: insightsRef.current.innerHTML,
                qty_companies: qtyCompanies,
                states: states,
                categories: typeBuyer,
            }
        }

        const resp = await createBusinessIntelligence(payload, (file ? new File([b64toBlob(file.split(",")[1], 'image/png')], "filename.png") : null));

        resp?.status == 200 ? navigate(`/bi/${resp?.data?.msg?.id}`) : handleError({ open: true, text: resp?.data?.msg })

        setLoading(false);
    }

    return (
        <Fragment>
            <Breadcrumb parent="Varejistas" title="Criação" />
            <Alert className={`alert-dismissible ${warning.open ? "show" : "fade"}`} color="danger dark"
                isOpen={warning.open}>
                {warning.text}
                <Button className="btn-close" color="default" onClick={() => setWarning({ open: false, text: warning.text })} ></Button>
            </Alert>
            <Card>
                <CardHeader />
                <CardBody className="tabbed-card">
                    <Nav className="nav-pills nav-primary">
                        <NavItem role="button">
                            <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                <i className="icofont icofont-man-in-glasses"></i>Inteligência
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className="container-fluid">
                                <div className="edit-profile">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <form>
                                                        <div className="user-image mb-5">
                                                            <div className="avatar d-flex justify-content-center">
                                                                <label htmlFor="image-avatar">
                                                                    <img className="pro border" style={{ maxWidth: "225px", maxHeight: "225px" }} role="button" alt="" src={buyer?.logo ? buyer?.logo : one} data-intro="This is Profile image" />
                                                                    <input className="pencil d-none" id="image-avatar" type="file" accept="image/png, image/jpeg" onChange={(e) => readUrl(e)} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Nome:*</label>
                                                            <div id="name" ref={nameRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer?.name ? buyer.name : "" }} role="textbox" className="form-control" style={{ minHeight: '35px' }}>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Consumidores:</label>
                                                            <div id="consumer" ref={consumerRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer?.consumer ? buyer.consumer : "" }} role="textbox" className="form-control text-break" style={{ minHeight: '70px' }}>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Sortimento de produtos:</label>
                                                            <div id="assortment" ref={assortmentRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer?.assortment ? buyer.assortment : "" }} role="textbox" className="form-control text-break" style={{ minHeight: '70px' }}>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Tamanho de lojas:</label>
                                                            <div id="storeSize" ref={storeSizeRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer?.storeSize ? buyer.storeSize : "" }} role="textbox" className="form-control text-break" style={{ minHeight: '70px' }}>
                                                            </div>
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Estado:</label>
                                                            <AsyncSelect isMulti defaultOptions={defaultStates} value={states} className="form-control p-0" required name="state" type="text"
                                                                onChange={(e) => setStates(e)} placeholder="Estado" />
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Tipo de Varejo:</label>
                                                            <AsyncSelect isMulti defaultOptions={defaultCategory} value={typeBuyer} className="form-control p-0" required name="buyerType" type="text"
                                                                onChange={(e) => setTypeBuyer(e)} placeholder="Tipo de varejo" />
                                                        </div>
                                                        <div className="form-group mb-3">
                                                            <label className="form-label">Número de Lojas:</label>
                                                            <input value={qtyCompanies} onChange={(e) => setQtyCompanies(e.target.value)} className="form-control" type="number" />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <form className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group mb-3">
                                                                <label className="form-label">Como é a apresentação de produtos?</label>
                                                                <div id="introduction" ref={apresentationRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer?.introduction ? buyer.introduction : "" }} role="textbox" className="form-control text-break" style={{ minHeight: '100px' }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group mb-3">
                                                                <label className="form-label">Termos de negociação</label>
                                                                <div id="tradeActions" ref={termsRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer?.tradeActions ? buyer.tradeActions : "" }} className="form-control text-break" style={{ minHeight: '100px' }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group mb-3">
                                                                <label className="form-label">Logística</label>
                                                                <div id="logistic" ref={logisticRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer?.logistic ? buyer.logistic : "" }} className="form-control text-break" style={{ minHeight: '100px' }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group mb-3">
                                                                <label className="form-label">Trade Marketing</label>
                                                                <div id="tradeMarketing" ref={tradeRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer?.tradeMarketing ? buyer.tradeMarketing : "" }} className="form-control text-break" style={{ minHeight: '100px' }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group mb-3">
                                                                <label className="form-label">Insights Comunidade</label>
                                                                <div id="insights" ref={insightsRef} contentEditable="true" onPaste={(e) => removeFormat(e)} dangerouslySetInnerHTML={{ __html: buyer?.insights ? buyer.insights : "" }} className="form-control text-break" style={{ minHeight: '100px' }}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer text-end">
                                                    <button className="btn btn-primary" style={{ width: "140px", height: "40px" }} onClick={(e) => handleSubmitBI(e)} type="submit">{loading ? <DefaultSpinner /> : "Salvar"}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default FakeBuyersCreate;